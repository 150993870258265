import {colors} from 'shared/ui/theme';
import Logo from 'shared/components/Logo';
import {Link} from 'react-router-dom';

const FourOFour = () => {
  return (
    <div style={{background: 'white', paddingBottom: 144}}>
      <div
        style={{
          marginBottom: 48,
          paddingTop: 24,
          paddingBottom: 24,
          paddingLeft: 96,
          paddingRight: 96,
          background: colors.primary.gradient,
        }}
      >
        <Logo />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 48,
          flexFlow: 'column',
        }}
      >
        <div style={{fontSize: 20, paddingBottom: 48}}>
          Oops! The page you're looking for does not exist
        </div>
        <Link to={`${window.urlPathBase}/`}>Go Back To Teachably's Home Page</Link>
      </div>
    </div>
  );
};

export default FourOFour;

import styled from '@emotion/styled';
import {spacing, shadows} from 'shared/ui/theme';
import getTeachablyColor from 'shared/utils/getTeachablyColor';

//Appends true for IE11, false otherwise
// https://stackoverflow.com/questions/17907445/how-to-detect-ie11
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

// flexbox bug info
// https://github.com/philipwalton/flexbugs

/***
 * A Layout Styling Container
 * provides spacing shortcut of 1-6 and shorthand of xPadding and yPadding
 * provides color shortcut to not have to import colors
 * provides animation between shadow changes (!)
 * no margin
 ***/

// should we allow border customization in this component??
// border: borderColor && `${borderWidth}px solid ${getTeachablyColor(borderColor)}`,

// TODO throw error if property isn't in this List

function checkIfMightScroll(overflow, overflowX, overflowY) {
  if (overflow === 'scroll' || overflow === 'auto') {
    return true;
  }
  if (overflowX === 'scroll' || overflowX === 'auto') {
    return true;
  }
  if (overflowY === 'scroll' || overflowY === 'auto') {
    return true;
  }
  return false;
}

export const Layout = styled.div(
  ({
    noWrap, // special settings
    xSpacing,
    ySpacing,
    background,
    backgroundImage,
    shadowElevation,
    verticalAlign,
    horizontalAlign,
    columns,
    expand,
    hidden,
    visibility,
    borderRadius,
    zIndex,
    cursor,
    opacity,
    transition,
    textAlign,
    position,
    top,
    left,
    right,
    bottom,
    width,
    height,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    boxShadow,
    border,
    borderLeft,
    borderRight,
    borderBottom,
    borderTop,
    margin,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    padding,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    overflow,
    overflowX,
    overflowY,
    alignSelf,
    wordBreak,
    overflowWrap,
    wordWrap,
    backdropFilter
  }) => ({
    // accepted standard style properties // 1-5 // 1-5 // a normal string or a path like states.error // 1-7 // top, center, bottom // left, center, right // boolean // boolean // boolean
    display: hidden ? 'none' : 'flex',
    transition: transition, // removed 2.3, what was the point? || 'box-shadow 300ms cubic-bezier(.4, 0, .2, 1)',

    // see link for 1 0 auto in IE11
    //https://stackoverflow.com/questions/21600345/flexbox-and-internet-explorer-11-displayflex-in-html
    //https://developer.mozilla.org/en-US/docs/Web/CSS/flex-basis
    flex: expand && (isIE11 ? '1 0 auto' : 1),

    // Rows by default, optionally put children in columns
    ...(columns
      ? {
          flexDirection: 'row',
          flexWrap: noWrap ? 'none' : 'wrap',
          justifyContent: horizontalAlign && getHorizontal(horizontalAlign),
          alignItems: verticalAlign && getVertical(verticalAlign),
        }
      : {
          flexDirection: 'column',
          flexWrap: noWrap ? 'none' : 'wrap',
          justifyContent: verticalAlign && getVertical(verticalAlign),
          alignItems: horizontalAlign && getHorizontal(horizontalAlign),
        }),

    paddingLeft: paddingLeft || spacing[xSpacing] || padding,
    paddingRight: paddingRight || spacing[xSpacing] || padding,
    paddingTop: paddingTop || spacing[ySpacing] || padding,
    paddingBottom: paddingBottom || spacing[ySpacing] || padding,
    background: background && getTeachablyColor(background),
    backgroundImage,
    boxShadow: (shadowElevation && shadows[shadowElevation]) || boxShadow,
    msOverflowStyle:
      checkIfMightScroll(overflow, overflowX, overflowY) &&
      '-ms-autohiding-scrollbar',
    WebkitOverflowScrolling:
      checkIfMightScroll(overflow, overflowX, overflowY) && 'touch',

    // i'm not sure the next two are even doing anything...
    //https://github.com/philipwalton/flexbugs#flexbug-2
    maxWidth: maxWidth || (isIE11 && '100%'),
    // https://github.com/angular/material/issues/7714
    flexShrink: isIE11 && 0,

    wordBreak,
    overflowWrap,
    wordWrap,

    // NOTE: https://github.com/philipwalton/flexbugs/issues/39
    minWidth,

    position,
    top,
    left,
    right,
    bottom,

    // setting 100% automatically screws with this
    width,
    height,

    maxHeight,
    minHeight,
    overflow,
    overflowX,
    overflowY,
    borderRadius,
    zIndex,
    textAlign,
    cursor,
    opacity,
    border,
    borderLeft,
    borderRight,
    borderBottom,
    borderTop,
    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    backdropFilter,
    visibility,
    alignSelf,
  })
);

// const flexContainerStyles = {
//   position: 'relative',
//   width: '100%',
//   height: '100%',
//   display: 'flex',
//   flex: 1,
//   flexWrap: 'wrap'
// };

function getHorizontal(horizontalAlign = 'flex-start') {
  let justifyContent = horizontalAlign;
  if (horizontalAlign === 'start' || horizontalAlign === 'left') {
    justifyContent = 'flex-start';
  } else if (horizontalAlign === 'end' || horizontalAlign === 'right') {
    justifyContent = 'flex-end';
  }
  return justifyContent;
}

function getVertical(verticalAlign = 'flex-start') {
  let alignItems = verticalAlign;
  if (verticalAlign === 'start' || verticalAlign === 'top') {
    alignItems = 'flex-start';
  } else if (verticalAlign === 'end' || verticalAlign === 'bottom') {
    alignItems = 'flex-end';
  }
  return alignItems;
}

import { useContext, createContext } from 'react';
import {mediaQueries} from 'shared/ui';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MediaQueryContext = createContext();

export function MediaQueryContextProvider({children}) {
  const smallestScreen = useMediaQuery(mediaQueries.lessThanExtraSmall);
  const extraSmallScreen = useMediaQuery(mediaQueries.lessThanSmall);
  const smallScreen = useMediaQuery(mediaQueries.lessThanMedium);
  const mediumScreen = useMediaQuery(mediaQueries.lessThanLarge);
  const largeScreen = useMediaQuery(mediaQueries.greaterThanMedium);
  const smallAndMediumExactScreen = useMediaQuery(mediaQueries.lessThanLarge);
  const mediumExactScreen = useMediaQuery(mediaQueries.medium);
  return (
    <MediaQueryContext.Provider
      value={{
        smallestScreen,
        extraSmallScreen,
        smallScreen,
        mediumScreen,
        largeScreen,
        mediumExactScreen,
        smallAndMediumExactScreen
      }}
    >
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}

// IMPORTANT: must import polyfill first or there's a bug in IE11
// if you use a language feature not supported in IE11 or react-app-polyfill
// be sure to add it here

// https://reactjs.org/docs/javascript-environment-requirements.html
// github.com/draft-js-plugins/draft-js-plugins/blob/master/FAQ.md#the-editor-throws-errors-in-internet-explorer-11
import 'react-app-polyfill/ie11';
import 'core-js/features/array/includes';
import 'core-js/features/string/includes';
import 'core-js/features/array/fill';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/object/entries';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/symbol';
import 'core-js/features/promise';
import 'core-js/features/weak-map';
import 'core-js/features/object/assign';
import 'core-js/features/array/from';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/repeat';
import 'core-js/features/string/trim-left';
import 'core-js/features/string/trim-right';
import 'core-js/features/url-search-params';
import 'raf/polyfill';

import {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {polyfill as smoothscrollpolyfill} from 'seamless-scroll-polyfill';
import {MediaQueryContextProvider} from 'shared/ui/MediaQueryContext';
import 'sanitize.css/sanitize.css';
import 'shared/ui/global-styles.css';
import ErrorBoundaryGlobal from 'shared/components/ErrorBoundaryGlobal';
import FourOFour from 'app-base/FourOFour';

const LandingPage = lazy(() =>
  import('landing-page/LandingPage2022/LandingPage')
);
const Help = lazy(() => import('landing-page/help/Help'));
const LoadApp = lazy(() => import('app-base/LoadApp'));
const ZoomLanding = lazy(() => import('app-base/ZoomLanding'));

// add smooth scroll support in safari and IE
// important for not losing context in the card manager
// TODO: 1/2021, this is old, check if browsers still need it
// and only add in IE11 perhaps
smoothscrollpolyfill();

window.account = 'public';

window.urlPathBase = process.env.REACT_APP_ENTERPRISE ? '/te' : '';

if (process.env.REACT_APP_ENTERPRISE) {
  window.urlPathBase = '/te';
} else if (process.env.REACT_APP_ZOOM) {
  window.urlPathBase = '/zoom';
} else {
  window.urlPathBase = '';
}

window.usersPath = `${window.account}/users/`;
window.sessionsPath = `${window.account}/spaces/`;
window.coursesPath = `${window.account}/courses/`;

// is used in a few places where an import of cardConfigUtils.js causes a redux problem
// keep this in sync as new card types are added
// it's used to not lock cards that don't need locked, etc
// TODO: redux is gone so remove mediaTypes from window and import
window.mediaTypes = [
  'article',
  'audio',
  'image',
  'link',
  'pdf',
  'video',
  'blank',
  'slide',
  'rolePlay',
];

window.onerror = function (msg, url, lineNo, columnNo, error = {}) {
  // there's a bug where if the laptop has been asleep a while
  // it might not reauthenticate or something
  // it's dangerous because it just silently breaks the app
  // this just forces a reload on that error so everything works
  // as time goes on re-evaluate if this is needed as firebase and teachably update
  // it's obviously not ideal, and maybe a little dangerous itself if somehow a loop starts
  // TODO: as of Nov 2020, it's been a very long time since this was added (it was a 1.0 thing)
  // so investigate removing it
  if (error && error.code === 'auth/network-request-failed') {
    window.location.reload(true);
  }

  return false;
};

ReactDOM.render(<Root />, document.getElementById('root'));

function Root() {
  return (
    <ErrorBoundaryGlobal>
      <Suspense fallback={<div />}>
        <Router>
          <MediaQueryContextProvider>
            <BaseRoutes />
          </MediaQueryContextProvider>
        </Router>
      </Suspense>
    </ErrorBoundaryGlobal>
  );
}

function BaseRoutes() {
  return (
    <Switch>
      <Route exact path={`/zoom`}>
        <ZoomLanding />
      </Route>
      <Route
        exact
        path={`${window.urlPathBase.length > 0 ? window.urlPathBase : '/'}`}
      >
        <LandingPage />
      </Route>
      {/* /help is probably being phased out in favor of /resources */}
      <Route path={`${window.urlPathBase}/help`}>
        <Help />
      </Route>
      <Route
        path={[
          `${window.urlPathBase}/auth`,
          `${window.urlPathBase}/facilitate`,
          `${window.urlPathBase}/teacher`, // legacy, for redirects to /facilitate
          `${window.urlPathBase}/join`,
          `${window.urlPathBase}/student`, // legacy, for redirects to /join
          `${window.urlPathBase}/manage-users`,
          `${window.urlPathBase}/presentation/:sessionId`,
          `${window.urlPathBase}/teachablyadmin`,
          `${window.urlPathBase}/courses`,
        ]}
      >
        <LoadApp />
      </Route>
      <Route>
        <FourOFour />
      </Route>
    </Switch>
  );
}

import styled from '@emotion/styled';
import getTeachablyColor from 'shared/utils/getTeachablyColor';
import textStyles from 'shared/ui/textStyles';

const truncateStyles = {
  // bug in Firefox without a width set
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

// by default the text is a div
// if i were starting over i'd use a custom p

const Text = styled.div(
  ({
    variant,
    lineHeight,
    letterSpacing,
    toUpperCase,
    color,
    weight,
    truncate,
    noUserSelect,
    transition,
    italic,
    fullWidth,
    textShadow,
  }) => ({
    margin: 0,
    width: fullWidth && '100%',
    ...textStyles[variant],
    color: color && getTeachablyColor(color),
    lineHeight: lineHeight
      ? lineHeight
      : textStyles[variant] && textStyles[variant].lineHeight,
    // wordBreak isn't standard
    // https://stackoverflow.com/questions/47243337/using-word-break-break-word-on-firefox
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    textTransform: toUpperCase && 'uppercase',
    fontWeight: weight
      ? weight
      : textStyles[variant] && textStyles[variant].fontWeight,
    transition,
    userSelect: noUserSelect && 'none',
    MozUserSelect: noUserSelect && 'none',
    WebkitUserSelect: noUserSelect && 'none',
    msUserSelect: noUserSelect && 'none',
    fontStyle: italic && 'italic',
    textShadow,
    letterSpacing: letterSpacing
      ? letterSpacing
      : textStyles[variant] && textStyles[variant].letterSpacing,
    ...(truncate && truncateStyles),
  })
);

export default Text;

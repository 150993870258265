import { Component } from 'react';
import Logo from 'shared/components/Logo';
import { colors } from 'shared/ui/theme';

class ErrorBoundaryGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);

    // it can for some reason have an info without an error
    if (error) {
      // window.logError(error, info)
    }
  }

  render() {
    const {inline} = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: inline ? '100%' : '100vw',
            height: inline ? '100%' : '100vh',
            background: colors.primary.gradient,
            paddingBottom: 144
          }}
        >
          <div
            style={{
              margin: '0 auto',
              paddingTop: 96,
              color: 'white',
              fontSize: 18,
              maxWidth: 600,
              fontWeight: 300,
              textAlign: 'center'
            }}
          >
            <Logo noLink height={96} />
            <div
              style={{
                paddingTop: 72,
                fontSize: 36,
                color: 'rgba(255, 255, 255, 0.56)'
              }}
            >
              Something went wrong.
            </div>

            <div style={{paddingTop: 72, paddingBottom: 24}}>
              Check your internet connection and try refreshing the browser
              window.
            </div>
            <div>
              If the problem won't go away, please contact us at{' '}
              <a
                href="mailto:teachably@talentsprout.com"
                style={{color: 'white', fontWeight: 500}}
              >
                teachably@talentsprout.com
              </a>
              .
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryGlobal;

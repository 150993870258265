import {colors} from 'shared/ui/theme';

function getTeachablyColor(string) {
    // if it doesn't use a period for a path, just return it
    // rgba has a . in the opacity setting
    if (string.indexOf('.') === -1 || string.substr(0, 4) === 'rgba') {
      return string;
    }
  
    // otherwise dig to the needed color
    const path = string.split('.');
    let color = colors;
    for (var i = 0; i < path.length; i++) {
      color = color[path[i]];
    }
    return color;
  }

  export default getTeachablyColor;
import {fontSizes, lineHeights} from 'shared/ui/theme';

const textStyles = {
    // these use the RealText font, for text you read, paragraphs, etc
    caption: {
      fontFamily:
        'RealText, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.extraSmall,
      lineHeight: lineHeights.extraSmall,
      letterSpacing: 0.7,
      fontWeight: 500
    },
    body: {
      fontFamily:
        'RealText, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.small,
      lineHeight: lineHeights.small,
      fontWeight: 400
    },
    body2: {
      fontFamily:
        'RealText, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.medium,
      fontWeight: 300,
      lineHeight: lineHeights.medium
    },
  
    // these use the RealHead font, for labels, titles, and general strips of text
    chatText: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400
    },

    finePrint: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.finePrint,
      letterSpacing: 0.7,
      lineHeight: lineHeights.extraSmall,
      fontWeight: 500
    },
    label: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.extraSmall,
      letterSpacing: 0.7,
      lineHeight: lineHeights.extraSmall,
      fontWeight: 500
    },
    button: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.small,
      fontWeight: 400,
      lineHeight: lineHeights.small,
      letterSpacing: 0.3
    },
    button2: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.medium,
      fontWeight: 500,
      lineHeight: lineHeights.medium
    },
    subheader: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.subheader,
      fontWeight: 500,
      lineHeight: lineHeights.subheader
    },
    headline: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.headline,
      fontWeight: 500,
      lineHeight: lineHeights.headline,
      letterSpacing: 1.2
    },
    display1: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.large,
      lineHeight: lineHeights.large,
      fontWeight: 400
    },
    display2: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.extraLarge,
      fontWeight: 300,
      lineHeight: lineHeights.extraLarge
    },
    display3: {
      fontFamily:
        'RealHead, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: fontSizes.huge,
      lineHeight: lineHeights.huge,
      fontWeight: 900,
      letterSpacing: 4
    }
  };

  export default textStyles;
// const sykesColors = {};

const teachablyClassic = {
  base: '#2A5580', // until 3.4 it was '#284264',
  // bright evolved to be the actual base by 3.0
  // todo: clean up naming
  bright: 'rgba(49, 100, 163, 1)', //#	336699
  light: '#3E5F7D',
  dark: '#1D3B59', // until 3.4: '#0E1B30',
  faint: '#F3F8FF',
  faintAccent: '#D3D8DE',
  analog: '#2EA6A2',
  analogCompl: '#F2A82C',
  analogLight: '#3192B0',
  steel: '#7388A3', // just used in icon images at the moment
  gradient:
    'linear-gradient(138deg, rgba(40,66,100,1) 0%, rgba(62,95,125,1) 10%, rgba(51,102,153,1) 70%, rgba(62,95,125,1) 100%)',
  analogGradient:
    'linear-gradient(138deg, #2EA6A2 0%, #32B3AE 10%, #2B9BBD 70%, #296EB3 100%)',
  analogComplGradient:
    'linear-gradient(138deg, #FFB22E 0%, #F2A82C 10%, #FFB22E 70%, #F2A82C 110%)',
  analogLightGradient:
    'linear-gradient(138deg, #2B4EBD 0%, #2E7BC7 10%, #359DBD 70%, #3192B0 100%)',
  blueGrayGradient:
    'linear-gradient(138deg, #4B5E70 0%, #325F88 10%, #325F88 70%, #4B5E70 100%)',
};

// const intuit = {
//   base: '#0077c5',
//   bright: 'rgb(66,149,224)',
//   light: 'rgb(50,117,191)',
//   dark: '#0E1B30',
//   faint: '#F3F8FF',
//   faintAccent: '#D3D8DE',
//   analog: '#2EA6A2',
//   analogCompl: '#F2A82C',
//   steel: '#7388A3', // just used in icon images at the moment
//   gradient:
//     'linear-gradient(138deg, rgba(40,66,100,1) 0%, rgba(62,95,125,1) 10%, rgba(51,102,153,1) 70%, rgba(62,95,125,1) 100%)',
// };

// 2020 color experiment
// extraLight: F2FAFF
// light: C0D7E4
// mediumLight: 94B7C9
// mediumDark: 5D8BA7
// dark: 3E5F7D (classic 'light')
// bright: 2254C9

// const teachablyNew = {
//   base: '#3E5F7D',
//   bright: '#2254C9',
//   light: '#C0D7E4',
//   dark: '#3E5F7D',
//   faint: '#F2FAFF',
//   faintAccent: '#7FB3D0',
//   analog: '#236264', // not used
//   steel: '#7388A3', // just used in icon images at the moment
//   gradient:
//     'linear-gradient(138deg, rgba(40,66,100,1) 0%, rgba(62,95,125,1) 12%, rgba(62,95,125,1) 30%, rgba(51,102,153,1) 100%)'
// };

// subtle background: F2FAFF

export const colors = {
  primary: teachablyClassic,

  states: {
    disabled: '#e9ecef',
    error: '#CC2F25',
    success: '#368024',
    warning: 'rgba(255, 224, 102, 1)', //'#ffe066'
    announcement: '#FFEFB2',
  },

  darkText: {
    primary: '#343a40',
    secondary: '#868e96',
    muted: '#adb5bd',
  },

  lightText: {
    primary: '#f8f9fa',
    secondary: '#adb5bd',
    muted: '#868e96',
  },

  stroke: {
    border: '#ced4da',
    divider: '#dee2e6',
  },

  interaction: '#e9ecef', // should be more like '#f1f3f5' but to light with MUI now

  // prefer a specific color above
  // this is here partly for legacy, partly for general convenience
  gray: {
    extraLight: '#f8f9fa', //'#f8f7f5',
    background: '#f1f3f5',
    light: '#e9ecef', //'#EBEBE9',
    mediumLight: '#ced4da', //'#B8B8B6',
    mediumDark: '#868e96', //'#787877', // rgb(120, 120, 119)
    dark: '#343a40', //'#393938', // rgb(57, 57, 56)
    extraDark: '#212529', //'#333332' //222222
    gradient:
      'linear-gradient(138deg, #ced4da 0%, #f1f3f5 10%, #e9ecef 70%, #f1f3f5 100%)',
    warm: '#F0EDE9',
  },

  // TODO add navbar color here
  // between mediumDark and dark...#495057

  blackInk: {
    extraLight: 'rgba(0, 0, 0, 0.12)',
    light: 'rgba(0, 0, 0, 0.32)',
    medium: 'rgba(0, 0, 0, 0.56)',
    // temp use dark gray
    dark: '#343a40',
  },
  whiteInk: {
    extraLight: 'rgba(255, 255, 255, 0.24)',
    light: 'rgba(255, 255, 255, 0.36)',
    medium: 'rgba(255, 255, 255, 0.56)',
    dark: 'rgba(255, 255, 255, 0.72)',
    extraDark: 'rgba(255, 255, 255, 0.87)',
  },
};

// culled from https://spectrum.adobe.com/page/color-for-data-visualization/
export const sequenceColors = {
  1: 'rgb(180,226,215)',
  2: 'rgb(152,211,202)',
  3: 'rgb(112,178,184)',
  4: 'rgb(77,143,167)',
  5: 'rgb(58,109,146)',
  6: 'rgb(50,92,135)',
  7: 'rgb(41,75,125)',
  8: 'rgb(35,58,115)',
  9: 'rgb(30,41,105)',
  10: 'rgb(21,1,82)',
};

export const groupColors = {
  purple: '#9E5FA2',
  brown: '#796558',
  green: '#3D9168',
  pink: '#DF948A',
  yellow: '#D5C387',
  red: '#DF5A49',
  blue: '#4F7DA1',
  orange: '#E27A3F',
  turquoise: '#55DBC1',
};

/*
const primary = {
  base: '#233A57',
  bright: '#3164A3',
  light: '#3E5F7D',
  dark: '#0E1B30'
}*/

export const spacing = {
  1: 8,
  2: 16,
  3: 24,
  4: 48,
  5: 72,
};

/*
TODO:
export const borderRadius = {
  small: 3,
  medium: 6,
  large: 12
}
*/

// mui animation for boxshadow cubic-bezier(.4, 0, .2, 1)
export const easing = {
  standard: '0.25,0.1,0.25,1',
  easeOut: '0,0,0.58,1',
  easeIn: '0.42,0,1,1',
  easeInOut: '0.42,0,0.58,1',
};

// export const fontSizes = {
//   extraSmall: 12,
//   small: 15,
//   medium: 19,
//   headline: 28,
//   large: 36,
//   extraLarge: 48,
//   huge: 96
// };

export const fontSizes = {
  finePrint: 9,
  extraSmall: 12,
  small: 15,
  medium: 18,
  subheader: 21,
  headline: 31,
  large: 37,
  extraLarge: 44,
  // in between in Minor Third is 53, 64, and 77
  huge: 92,
};

export const lineHeights = {
  extraSmall: '17px',
  small: '21px',
  medium: '26px',
  subheader: '28px',
  headline: '40px',
  large: '48px',
  extraLarge: '58px',
  huge: '120px',
};
// TODO: body = 1.5x short message = 1.2x

// add letterSpacing: "0.05em" ???

export const borderRadius = 4;

// TODO: space constants: inset, inset squish, inset stretch, stack, inline, and grid.
//https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62

// Inset Component - adds margins - props: size (narrow, wide... maybe s, m, l, xl), vertical (squish-50%, inline-0% top and bottom 0 left and some on right) to shrink top/bottom, stretch (boolean) to fill
// Stack Component - adds (a bit on) top and (more on) bottom margins in lists

export const gutter = 8;

export const shadows = {
  [-1]: 'inset 1px 0px 1px -1px rgba(0, 0, 0, 0.2), inset 1px 0px 2px 0px rgba(0, 0, 0, 0.14), inset 0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
  0: '0px 0px 0px 0px rgba(0,0,0,0)',
  // 2dp
  1: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  // 4dp
  2: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  // 6dp
  3: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  // 8dp
  4: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  // 12dp
  5: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
  // 16dp
  6: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
  // 24dp
  7: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
};

/*
NOT OFFICIAL BUT GOOD COLOR SCALES FOR IDEAS OR QUICK DEMO
greyscale:[
"#f7f7f7","#cccccc","#969696","#636363","#252525"],

qualitative:[
"#334D5C","#45B29D","#EFC94C","#E27A3F","#DF5A49",
"#4F7DA1","#55DBC1","#EFDA97","#E2A37F","#DF948A"],

heatmap:["#428517","#77D200","#D6D305","#EC8E19","#C92B05"],
warm:["#940031","#C43343","#DC5429","#FF821D","#FFAF55"],
cool:["#2746B9","#0B69D4","#2794DB","#31BB76","#60E83B"],
red:["#611310","#7D1D1D","#B02928","#B02928","#D86B67"],
blue:["#002C61","#004B8F","#006BC9","#3795E5","#65B4F4"],
green:["#354722","#466631","#649146","#8AB25C","#A9C97E"]};
*/

/*
Elevation (dp)
Component
24
Dialog

Picker
16
Nav drawer

Right drawer

Modal bottom Sheet
12
Floating action button (FAB - pressed)
9
Sub menu (+1dp for each sub menu)
8
Bottom navigation bar

Menu

Card (when picked up)

Raised button (pressed state)
6
Floating action button (FAB - resting elevation)

Snackbar
4
App Bar
3
Refresh indicator

Quick entry / Search bar (scrolled state)
2
Card (resting elevation) *

Raised button (resting elevation)*

Quick entry / Search bar (resting elevation)
1
Switch
*/

import logoSrc from 'shared/assets/images/logos/logo-white.png';
import TeachablyLogo from 'shared/assets/images/logos/teachably-logo.png';
import {Link} from 'react-router-dom';

// do not have an alt because it flashes in Firefox and looks ugly

export default ({sykes = false, height = 48, noLink}) =>
  noLink ? (
    <Image sykes={sykes} height={height} noLink={noLink} />
  ) : (
    <Link to={`${window.urlPathBase}/`}>
      <Image sykes={sykes} height={height} />
    </Link>
  );

const Image = ({sykes, height, noLink}) => (
  <img
    alt=""
    src={sykes ? TeachablyLogo : logoSrc}
    style={{height, maxWidth: '100%', cursor: noLink ? 'default' : 'pointer'}}
  />
);
// CombinationLogo is legacy, delete in 2.0

const breakpoints = {
  extraSmall: 480,
  small: 768,
  medium: 992,
  large: 1200,
  extraLarge: 1400
};

export default {
  // these are the main breakpoints used in the app
  lessThanExtraSmall: `(max-width: ${breakpoints.extraSmall}px)`,
  lessThanSmall: `(max-width: ${breakpoints.small}px)`,
  lessThanMedium: `(max-width: ${breakpoints.medium}px)`,
  lessThanLarge: `(max-width: ${breakpoints.large}px)`,

  // used for an exact tablet size basically, like with conditionals of xsmall and xlarge
  smallAndMedium: `(min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.large}px)`,

  // should be useful, but hasn't been yet
  extraLarge: `(min-width: ${breakpoints.extraLarge}px)`,

  // I haven't really used these, preferring just lessThan in almost every case
  extraSmall: `(max-width: ${breakpoints.small}px)`,
  small: `(min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.medium}px)`,
  medium: `(min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large}px)`,
  large: `(min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.extraLarge}px)`,

  // haven't hit a use for this yet, but just in case
  greaterThanSmall: `(min-width: ${breakpoints.medium}px)`,
  greaterThanMedium: `(min-width: ${breakpoints.large}px)`,
};
